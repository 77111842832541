<template>
  <div class="app-context">
    <div class="context research-hospital">
      <div class="search">
        <div class="s-icon" @click="searchDoctor()">
          <span class="material-icons-outlined">search</span>
        </div>
        <div class="input-wrap">
          <input
            type="text"
            v-model="searchOpt.searchTxt"
            maxlength="40"
            @keyup.enter="searchDoctor()"
          />
        </div>
      </div>
      <div
        class="explain"
        style="height: 30px; margin: -15px 0px 20px 10px; font-size: 18px"
      >
        <span
          >의사명, 병원명, 증상, 의료기기, 전공을 비롯한 다양한 키워드의 검색을
          통해 의사(병원)을 검색할 수 있습니다.</span
        >
      </div>
      <div class="choice-opt-wrap">
        <div class="default-opt">
          <div
            class="item"
            v-for="(defaultItem, index) in getCheckValue.default"
            :key="index + 'A'"
            :class="{ active: defaultItem.flag }"
            @click="checkPick('first', index)"
          >
            <div class="check">
              <span class="material-icons-outlined">done</span>
            </div>
            <p class="title">{{ defaultItem.title }}</p>
          </div>
        </div>
        <div class="distance-opt">
          <h3>거리 기준점:</h3>
          <div class="d-item-wrap row">
            <div
              class="item"
              v-for="(distanceItem, index) in getCheckValue.distance"
              :key="index + 'B'"
              :class="{ active: distanceItem.flag }"
              @click="checkPick('second', index)"
            >
              <div class="check">
                <span class="material-icons-outlined">done</span>
              </div>
              <p class="title">{{ distanceItem.title }}</p>
            </div>
            <p class="address-data" v-if="searchOpt.addr !== null">
              {{ searchOpt.addr }}
            </p>
          </div>
        </div>
      </div>
      <div class="r-list" v-if="this.hospital.length > 0">
        <div
          class="item row hospital-list"
          v-for="(hospitalInfo, hospitalIndex) in hospital"
          :key="hospitalIndex"
          @click="selectDoctor(hospital[hospitalIndex].drId, hospitalIndex)"
        >
          <div class="logo col-xl-1 col-lg-12">
            <img
              :src="
                'https://www.drrefer.net/api/pic/' + hospitalInfo.drPicAttchId
              "
              v-if="
                hospitalInfo.drPicAttchId !== null &&
                hospitalInfo.drPicAttchId !== ''
              "
            />
            <img
              :src="
                'https://www.drrefer.net/api/pic/' + hospitalInfo.logoAttchId
              "
              v-else-if="
                hospitalInfo.logoAttchId !== null &&
                hospitalInfo.logoAttchId !== ''
              "
            />
            <!-- <img src="@/assets/images/logo_minimi.svg" v-else /> -->
            <img
              src="@/assets/images/person_black_48dp.svg"
              v-else
              style="opacity: 10%"
            />
            <!-- <span class="material-icons-outlined" v-else>face</span> -->
          </div>
          <div class="hospital col-xl-1 col-lg-12">
            <div class="title row">
              <h5 style="color: #313c49">{{ hospitalInfo.drNm }}</h5>
              <div style="width: 15px" />
              <h5>{{ hospitalInfo.hospNm }}</h5>
            </div>
            <div class="list-wrap">
              <div class="info">
                <span class="material-icons-outlined">place</span>
                <span>{{ hospitalInfo.addr2 }} </span>
                <span class="distance" v-if="isLogin">{{
                  getDistance(hospitalInfo.distance)
                }}</span>
              </div>
              <ul class="info">
                <li v-if="hospitalInfo.affilYn === 'Y'">
                  {{ hospitalInfo.affilCollage }}
                </li>
                <li
                  v-if="
                    hospitalInfo.gradYear !== null &&
                    hospitalInfo.gradYear !== '' &&
                    hospitalInfo.affilYn === 'Y'
                  "
                >
                  {{ hospitalInfo.gradYear }}
                </li>
                <li
                  v-if="
                    hospitalInfo.trainHosp !== null &&
                    hospitalInfo.trainHosp !== ''
                  "
                >
                  {{ hospitalInfo.trainHosp }}
                </li>
              </ul>
            </div>
          </div>
          <div
            class="note-warp col-xl-1 col-lg-12"
            @click="writeMessage(hospitalInfo)"
            @click.stop
            v-if="isLogin"
          >
            <span class="material-icons-outlined">email</span>
            <p>쪽지 보내기</p>
          </div>

          <ul class="search-txt col-xl-1 col-lg-12">
            <li
              v-if="
                hospitalInfo.diagNm1 !== null && hospitalInfo.diagNm1 !== ''
              "
            >
              {{ hospitalInfo.diagNm1 }}
            </li>
            <li
              v-if="
                hospitalInfo.diagNm2 !== null && hospitalInfo.diagNm2 !== ''
              "
            >
              {{ hospitalInfo.diagNm2 }}
            </li>
            <li
              v-if="
                hospitalInfo.diagNm3 !== null && hospitalInfo.diagNm3 !== ''
              "
            >
              {{ hospitalInfo.diagNm3 }}
            </li>
            <li
              v-if="
                hospitalInfo.symptomNm1 !== null &&
                hospitalInfo.symptomNm1 !== ''
              "
            >
              {{ hospitalInfo.symptomNm1 }}
            </li>
            <li
              v-if="
                hospitalInfo.symptomNm2 !== null &&
                hospitalInfo.symptomNm2 !== ''
              "
            >
              {{ hospitalInfo.symptomNm2 }}
            </li>
            <li
              v-if="
                hospitalInfo.symptomNm3 !== null &&
                hospitalInfo.symptomNm3 !== ''
              "
            >
              {{ hospitalInfo.symptomNm3 }}
            </li>
            <li
              v-if="
                hospitalInfo.operNm1 !== null && hospitalInfo.operNm1 !== ''
              "
            >
              {{ hospitalInfo.operNm1 }}
            </li>
            <li
              v-if="
                hospitalInfo.operNm2 !== null && hospitalInfo.operNm2 !== ''
              "
            >
              {{ hospitalInfo.operNm2 }}
            </li>
            <li
              v-if="
                hospitalInfo.operNm3 !== null && hospitalInfo.operNm3 !== ''
              "
            >
              {{ hospitalInfo.operNm3 }}
            </li>
            <li
              v-if="
                hospitalInfo.equipNm1 !== null && hospitalInfo.equipNm1 !== ''
              "
            >
              {{ hospitalInfo.equipNm1 }}
            </li>
            <li
              v-if="
                hospitalInfo.equipNm2 !== null && hospitalInfo.equipNm2 !== ''
              "
            >
              {{ hospitalInfo.equipNm2 }}
            </li>
            <li
              v-if="
                hospitalInfo.equipNm3 !== null && hospitalInfo.equipNm3 !== ''
              "
            >
              {{ hospitalInfo.equipNm3 }}
            </li>
          </ul>
        </div>
      </div>
      <div class="list-empty" v-else>
        <div class="spinner" v-if="isChildLoading">
          <transition name="slide-fade" mode="in-out" v-if="isChildLoading">
            <beat-loader
              :loading="isChildLoading"
              :color="loader.color"
              :size="loader.size"
            ></beat-loader>
          </transition>
        </div>
        <div class="item" v-if="!isChildLoading">
          <div class="logo-wrap">
            <img src="@/assets/images/logo_unplugged.svg" />
          </div>
          <h4>조회결과 없음</h4>
        </div>
      </div>
      <div class="pagination-wrap">
        <p
          class="navigation-act prev"
          v-if="listStatus.first > 5"
          @click="navigatorAct('prev')"
        >
          <!-- Prev -->
          <v-icon> mdi-chevron-left </v-icon>
        </p>
        <ul id="pagination">
          <li
            v-for="(pagination, index) in listStatus.currentPaginationList"
            :key="index + 'j'"
            @click="loadSerchList(pagination, index)"
            :class="
              (listStatus.currentPage - 1) % listStatus.pageCount === index
                ? 'active'
                : ''
            "
          >
            <!-- :class="{ paginationLast: index === listStatus.currentPaginationList.length-1 }" -->
            <p>{{ pagination }}</p>
          </li>
        </ul>
        <p
          class="navigation-act next"
          v-if="
            listStatus.next > 5 &&
            listStatus.pageCount < listStatus.totalPage &&
            listStatus.last !== listStatus.totalPage
          "
          @click="navigatorAct('next')"
        >
          <!-- Next -->
          <v-icon> mdi-chevron-right </v-icon>
        </p>
      </div>
      <button id="focusControl"></button>
    </div>
    <address-popup
      @responseAddress="bringAddress($event)"
      v-if="checkAddressPopup"
      :popupSetAddress="popupSetAddress"
    />
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from "@/components/modal/DefaultPopup";
import BeatLoader from "vue-spinner/src/BeatLoader";
import AddressPopup from "@/components/modal/AddressPopup";
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";

export default {
  components: {
    DefaultPopup,
    BeatLoader,
    AddressPopup,
  },
  data() {
    return {
      headerOption: {
        navOrder: 1,
      },
      popupSet: {},
      popupSetAddress: {},
      sideSet: {
        menuName: "research",
        sideTitle: "검색결과",
      },
      loader: {
        loading: false,
        color: "#146f83",
        size: "18px",
      },
      setCount: false,
      getCheckValue: {
        default: [
          /*{
            flag: false,
            title: "거리순",
          },
          {
            flag: false,
            title: "추천순",
          },
          {
            flag: false,
            title: "되의뢰율순",
          },
          {
            flag: false,
            title: "의뢰순",
          },
          {
            flag: true,
            title: "등록순",
          },*/
        ],
        distance: [
          {
            flag: true,
            title: "전국 지역 기준",
          },
          {
            flag: false,
            title: "입력 주소 기준",
          },
        ],
      },
      searchOpt: {
        addrType: 1, // distance 옵션
        orderType: 1, // default 옵션,
        recordsPerPage: "", // 한번에 불러올 갯수
        searchTxt: "", // 검색어
        preSearchTxt: "", // 이전검색어 저장
        addr: null,
      },
      listStatus: {
        totalDataCnt: null, // 조회하여 가져온 총 데이터의 수
        dataOnPage: 10, // 한 페이지에 내려줄 데이터의 수
        pageCount: 5, // 한 페이지에 1~5까지 보여준다.
        totalPage: null, // 페이지네이션에 출력한 총 페이지의 수
        currentPage: 1, // 현재 페이지
        prev: null,
        next: null,
        first: null,
        last: null,
        currentPaginationList: [],
        /**
         * totalDataCnt: 22
         * currentPage: 1
         * totalPage: 3
         * pageGroup: 1
         * last: 3
         * first: -1
         * next: 4
         * prev: -2
         */
      },
      testSample: [
        {
          logoAttchId: require("@/assets/images/renew.png"),
          hospNm: "땡큐이비인후과",
          drNm: "하정훈원장",
          distance: 11712,
          addr2: "시흥시 정왕동",
          trainHosp: "고신대병원",
          gradYear: "2016",
          diagNm1: "병명1 병명1",
          diagNm2: "병명2 병명2",
          diagNm3: "병명3 병명3",
          symptomNm1: "증상1 증상1",
          symptomNm2: "증상2 증상2",
          symptomNm3: "증상3 증상3",
          operNm1: "수술1 수술1",
          operNm2: "수술2 수술2",
          operNm3: "수술3 수술3",
          equipNm1: "장비1 장비1",
          equipNm2: "장비2 장비2",
          equipNm3: "장비3 장비3",
        },
        {
          logoAttchId: require("@/assets/images/thanqlogo.svg"),
          hospNm: "땡큐이비인후과",
          drNm: "하정훈원장",
          distance: 11712,
          addr2: "시흥시 정왕동",
          trainHosp: "고신대병원",
          gradYear: "2016",
          diagNm1: "병명1 병명1",
          diagNm2: "병명2 병명2",
          diagNm3: "병명3 병명3",
          symptomNm1: "증상1 증상1",
          symptomNm2: "증상2 증상2",
          symptomNm3: "증상3 증상3",
          operNm1: "수술1 수술1",
          operNm2: "수술2 수술2",
          operNm3: "수술3 수술3",
          equipNm1: "장비1 장비1",
          equipNm2: "장비2 장비2",
          equipNm3: "장비3 장비3",
        },
      ],
      hospital: [],
      isLogin: false,
    };
  },
  created() {
    window.scrollTo(0, 0);
    if (history.pushState !== undefined) {
      history.pushState({}, "", location.href);
      window.onpopstate = () => {
        this.handleGoback();
      };
    }
    //console.log("getSearchParams", this.getSearchParams);
    if (this.getSearchParams.length !== 0) {
      this.doSearch(this.getSearchParams);
    }
    if (localStorage.getItem("token") === null) {
      this.isLogin = false;
    } else {
      this.isLogin = true;
      this.getCheckValue.distance = [
        {
          flag: false,
          title: "전국 지역 기준",
        },
        {
          flag: true,
          title: "입력 주소 기준",
        },
      ];
    }
  },
  mounted() {},
  computed: {
    ...mapState("basic", ["checkPopup", "isChildLoading", "checkAddressPopup"]),
    ...mapGetters("basic", ["getSearchParams"]),
    ...mapGetters("join", ["GET_BASIC"]),
    ...mapGetters("referList", ["GET_SEARCH_LIST"]),
    getDistance() {
      return (value) => {
        const calcuratedKM = Math.floor(value / 1000);
        const calcuratedM = Math.floor(value);
        if (calcuratedKM < 1) {
          return calcuratedM + "m";
        } else {
          return calcuratedKM + "km";
        }
      };
    },
  },
  methods: {
    ...mapMutations("basic", [
      "SET_POPUP",
      "SET_CHILD_LOADER",
      "SET_ADDRESS_POPUP",
    ]),
    ...mapActions("join", ["FETCH_BASIC_ADDRESS"]),
    ...mapMutations("util", ["SET_LIST_DOC_INFO"]),
    ...mapActions("referList", ["BRING_SEARCH_LIST"]),
    writeMessage(item) {
      //console.log(item);
      //this.$refs.appheader.callMessage(item);
      const sendAppheaderItem = {
        method: "callMessage",
        item: item,
      };
      this.$emit("send-app-header-method", sendAppheaderItem);
    },
    searchAddress() {
      this.SET_ADDRESS_POPUP(true);
      this.popupSetAddress.popType = "address";
    },
    handleGoback() {
      if (this.setCount) {
        this.$router.push("/").catch(() => {});
      } else {
        history.go(1);
      }
      this.setCount = true;
      // 클로즈 함수 구현
    },
    doSearch(search) {
      this.$nextTick((_) => {
        this.searchOpt.searchTxt = search;
        this.searchDoctor();
      });
    },
    async bringAddress(address) {
      let result = null;
      await this.FETCH_BASIC_ADDRESS(address.address).then((data) => {
        // console.log("data : ", data);
        result = data.result;
      });
      if (result === false) {
        this.SET_POPUP(true);
        this.popupSet.title = "";
        this.popupSet.content = "위치정보를 받아오지 못했습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        this.SET_ADDRESS_POPUP(false);
        this.getCheckValue.distance[0].flag = false;
        this.getCheckValue.distance[1].flag = true;
      } else {
        this.getCheckValue.distance[0].flag = false;
        this.getCheckValue.distance[1].flag = true;
        this.SET_ADDRESS_POPUP(false);
        this.getCheckValue.distance[0].flag = false;
        this.getCheckValue.distance[1].flag = true;
        this.searchOpt.addrType = 2;
        this.searchOpt.addr = address.address + " " + address.buildingName;

        if (
          this.searchOpt.searchTxt !== null &&
          this.searchOpt.searchTxt !== ""
        ) {
          this.searchDoctor();
        }
      }
    },
    initPaginationEffect() {
      for (
        let k = 0;
        k < document.querySelector("#pagination").childElementCount;
        k++
      ) {
        /*console.log(
          "Ds",
          document.querySelector("#pagination").childNodes[k].childNodes[0]
            .textContent
        );*/
        if (
          document
            .querySelector("#pagination")
            .childNodes[k].classList.contains("active")
        ) {
          document
            .querySelector("#pagination")
            .childNodes[k].classList.remove("active");
        }
      }
    },
    async loadSerchList(currentPage, order) {
      //console.log("currentPage : ", currentPage);
      //console.log("order : ", order);

      if (currentPage === null || currentPage === undefined) currentPage = "";

      var currentPageBoo = /^[0-9]{1,100}$/;

      if (!currentPageBoo.test(currentPage)) {
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content = "리스트를 불러오는데 실패하였습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      if (order === null || order === undefined) order = "";

      var orderBoo = /^[0-9]{1,100}$/;

      if (!orderBoo.test(order)) {
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content = "리스트를 불러오는데 실패하였습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      this.hospital = [];
      this.SET_CHILD_LOADER(true);
      // this.initPaginationEffect();
      // if (this.listStatus.totalDataCnt >= 1) {
      //   document
      //     .querySelector("#pagination")
      //     .childNodes[order].classList.add("active");
      // }
      let searchOption;
      try {
        if (localStorage.getItem("token") === null) {
          searchOption = {
            orderType: this.searchOpt.orderType,
            addrType: 2,
            searchWord: this.searchOpt.searchTxt,
            recordsPerPage: this.listStatus.dataOnPage,
            pageNo: currentPage,
            addr: this.searchOpt.addr,
            auth: false,
          };
        } else if (this.searchOpt.addrType === 2) {
          //console.log("option : 2");
          searchOption = {
            orderType: this.searchOpt.orderType,
            addrType: this.searchOpt.addrType,
            searchWord: this.searchOpt.searchTxt,
            recordsPerPage: this.listStatus.dataOnPage,
            pageNo: currentPage,
            addr: this.searchOpt.addr,
            auth: true,
          };
        } else {
          //console.log("option : else");
          searchOption = {
            orderType: this.searchOpt.orderType,
            addrType: this.searchOpt.addrType,
            searchWord: this.searchOpt.searchTxt,
            recordsPerPage: this.listStatus.dataOnPage,
            pageNo: currentPage,
            auth: true,
          };
        }

        //console.log("searchOption : ", searchOption);

        // 유효성 검사
        // 1. 타입
        if (
          searchOption.orderType === null ||
          searchOption.orderType === undefined
        )
          searchOption.orderType = "";

        var orderTypeBoo = /^[0-9]{1,100}$/;

        if (!orderTypeBoo.test(searchOption.orderType)) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "리스트를 불러오는데 실패하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 2. 주소기준
        if (
          searchOption.addrType === null ||
          searchOption.addrType === undefined
        )
          searchOption.addrType = "";

        var addrTypeBoo = /^[0-9]{1,100}$/;

        if (!addrTypeBoo.test(searchOption.addrType)) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "리스트를 불러오는데 실패하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 3. 검색어
        if (
          searchOption.searchWord === null ||
          searchOption.searchWord === undefined
        )
          searchOption.searchWord = "";

        if (
          searchOption.searchWord.length < 0 ||
          searchOption.searchWord.length > 200
        ) {
          this.SET_POPUP(true);
          this.popupSet.title = "검색어 확인";
          this.popupSet.content = "200자 이내의 검색어를 입력해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 4. 페이지당 보여줄 수
        if (
          searchOption.recordsPerPage === null ||
          searchOption.recordsPerPage === undefined
        )
          searchOption.recordsPerPage = "";

        var recordsPerPageBoo = /^[0-9]{1,100}$/;

        if (!recordsPerPageBoo.test(searchOption.recordsPerPage)) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "리스트를 불러오는데 실패하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        await this.BRING_SEARCH_LIST(searchOption).then(() => {
          //console.log("가져온 결과값", this.GET_SEARCH_LIST);
          this.listStatus.totalDataCnt = this.GET_SEARCH_LIST.param.totalCount;
          /** this.listStatus.currentPage = this.GET_SEARCH_LIST.param.pageNo;*/
          /** this.listStatus.dataOnPage = this.GET_SEARCH_LIST.param.recordsPerPage; */
          this.searchOpt.preSearchTxt = this.GET_SEARCH_LIST.param.searchWord;
          setTimeout(() => {
            this.SET_CHILD_LOADER(false);
            this.hospital = this.GET_SEARCH_LIST.list;
            this.listStatus.currentPage = order + 1;
          }, 100);
        });
      } catch (error) {
        //console.log("error: ", error);
        setTimeout(() => {
          this.SET_CHILD_LOADER(false);
        }, 800);
      }
    },
    navigatorAct(type) {
      let first = this.listStatus.first;
      let last = this.listStatus.last;
      const totalPage = this.listStatus.totalPage;
      const paginationListArray = [];
      let next = this.listStatus.next;
      let prev = this.listStatus.prev;
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      this.listStatus.currentPaginationList = paginationListArray;
      // this.initPaginationEffect();
      /**
       * first next last
       * 보정 가정
       * */
      if (type === "prev") {
        last = first - 1;
        next = last + 1;
        first = first - 5;
        prev = first - 1;
        /**
         * 11    15
         * 6     10
         * currentPage = first
         * totalCount = 13
         */
        this.listStatus.currentPage = last;
      } else if (type === "next") {
        first = first + 5;
        prev = first - 1;
        //console.log("totalPage : " + totalPage);
        if (totalPage > last + 5) {
          last = last + 5;
          next = last + 1;
        } else {
          last = totalPage;
          next = last;
        }
        //console.log("last :" + last);
        /**
         * 6    10
         * 11   15
         * currentPage = first
         * totalCount = 13
         */
        this.listStatus.currentPage = first;
      }

      this.listStatus.first = first;
      this.listStatus.last = last;
      this.listStatus.prev = prev;
      this.listStatus.next = next;
      this.calculatedPaginationList(first, last, type);
      // console.log(this.listStatus.first);
      // console.log(this.listStatus.last);
    },
    async calculatedPaginationList(first, last, type) {
      const paginationListArray = [];
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      this.listStatus.currentPaginationList = paginationListArray;
      if (type === "prev") {
        this.$nextTick((_) => {
          this.loadSerchList(this.listStatus.last, 4);
        });
        // this.$nextTick((_) => {
        //   this.loadSerchList(this.listStatus.last, 4);
        //   for (
        //     let k = 0;
        //     k < document.querySelector("#pagination").childElementCount;
        //     k++
        //   ) {
        /*console.log(
              "재료",
              document.querySelector("#pagination").childNodes[k].childNodes[0]
                .textContent
            );*/
        //     if (
        //       Number(
        //         document.querySelector("#pagination").childNodes[k]
        //           .childNodes[0].textContent
        //       ) === Number(this.listStatus.last)
        //     ) {
        //       document
        //         .querySelector("#pagination")
        //         .childNodes[k].classList.add("active");
        //     }
        //   }
        // });
      } else if (type === "next") {
        await this.loadSerchList(this.listStatus.currentPage, 0).then(() => {});
        // this.loadSerchList(this.listStatus.currentPage, 0).then(() => {
        //   document
        //   .querySelector("#pagination")
        //   .childNodes[0].classList.add("active");
        // });
      } else {
        if (this.hospital.length > 0) {
          await this.$nextTick((_) => {
            const target = document.querySelectorAll(".hospital-list");
            if (target !== undefined) {
              function addElsePagination(currentPage) {
                setTimeout(() => {
                  if (currentPage !== null) {
                    if (
                      document.querySelector("#pagination") === null ||
                      document.querySelector("#pagination") === undefined
                    ) {
                      return addElsePagination(currentPage);
                    }
                    if (
                      document.querySelector("#pagination").childNodes[
                        currentPage - 1
                      ] === null ||
                      document.querySelector("#pagination").childNodes[
                        currentPage - 1
                      ] === undefined
                    ) {
                      return addElsePagination(currentPage);
                    }
                    if (
                      document.querySelector("#pagination").childNodes[
                        currentPage - 1
                      ].classList === undefined
                    ) {
                      return addElsePagination(currentPage);
                    }
                    // document
                    //   .querySelector("#pagination")
                    //   .childNodes[currentPage - 1].classList.add("active");
                  } else {
                    return addElsePagination(currentPage);
                  }
                }, 100);
              }
              addElsePagination(this.listStatus.currentPage);
            }
          });
        }

        // if (this.listStatus.totalDataCnt >= 1) {
        //   this.$nextTick((_) => {
        //     document
        //       .querySelector("#pagination")
        //       .childNodes[this.listStatus.currentPage - 1].classList.add(
        //         "active"
        //       );
        //   });
        // }
      }
    },
    preParePagination() {
      /**
      this.listStatus.totalDataCnt = 122;
       */
      /** api로 list를 불러온 과정 생략 */
      const totalDataCnt = this.listStatus.totalDataCnt;
      const currentPage = this.listStatus.currentPage;
      /*console.log("totalDataCnt", totalDataCnt);
      console.log("currentPage", currentPage);*/
      const dataOnPage = this.listStatus.dataOnPage;
      const pageCount = this.listStatus.pageCount;
      const totalPage = Math.ceil(totalDataCnt / dataOnPage);
      const pageGroup = Math.ceil(currentPage / pageCount);
      /*console.log("totalPage", totalPage);
      console.log("pageGroup", pageGroup);*/
      let last = pageGroup * pageCount;
      if (last > totalPage) {
        last = totalPage;
      }
      let first = last - (pageCount - 1);
      const next = last + 1;
      const prev = first - 1;
      if (totalPage < 1) {
        first = last;
      }
      /*console.log("last : " + last);
      console.log("first : " + first);
      console.log("next :" + next);
      console.log("prev :" + prev);*/
      this.listStatus.totalPage = totalPage;
      this.listStatus.prev = prev;
      this.listStatus.next = next;
      this.listStatus.first = first;
      this.listStatus.last = last;
      this.calculatedPaginationList(first, last);
      /**
       * totalDataCnt: 22
       * currentPage: 1
       * totalPage: 3
       * pageGroup: 1
       * last: 3
       * first: -1
       * next: 4
       * prev: -2
       */
      /** [next]
       *  first -> pageGroup  * dataOnPage + 1
       *  last  -> ((pageGroup + 1) * dataOnPage) - 1
       *  [prev]
       *  first -> ((pageGroup - 2) * dataOnPage) + 1
       *  last  -> ((pageGroup - 1) * dataOnpage) - 1
       *
       */
    },
    async searchDoctor() {
      if (
        this.searchOpt.searchTxt !== null &&
        this.searchOpt.searchTxt !== ""
      ) {
        this.hospital = [];
        this.SET_CHILD_LOADER(true);
        let searchOption;
        // this.initPaginationEffect();
        this.listStatus.currentPage = 1;
        this.SET_CHILD_LOADER(false);
        //console.log("확인");
        try {
          if (localStorage.getItem("token") === null) {
            if (this.searchOpt.addrType === 2) {
              //console.log("option : 21");
              searchOption = {
                orderType: 1,
                addrType: this.searchOpt.addrType,
                searchWord: this.searchOpt.searchTxt,
                recordsPerPage: this.listStatus.dataOnPage,
                pageNo: this.listStatus.currentPage,
                addr: this.searchOpt.addr,
                auth: false,
              };
            } else {
              //console.log("option : else1");
              this.searchOpt.addr = this.GET_BASIC.addr;
              searchOption = {
                orderType: 5,
                addrType: 2,
                searchWord: this.searchOpt.searchTxt,
                recordsPerPage: this.listStatus.dataOnPage,
                pageNo: this.listStatus.currentPage,
                addr: this.searchOpt.addr,
                auth: false,
              };
              //console.log(searchOption);
            }
          } else if (this.searchOpt.addrType === 2) {
            //console.log("option : 2");
            searchOption = {
              orderType: this.searchOpt.orderType,
              addrType: this.searchOpt.addrType,
              searchWord: this.searchOpt.searchTxt,
              recordsPerPage: this.listStatus.dataOnPage,
              pageNo: this.listStatus.currentPage,
              addr: this.searchOpt.addr,
              auth: true,
            };
          } else {
            //console.log("option : else");
            this.searchOpt.addr = this.GET_BASIC.addr;
            searchOption = {
              orderType: this.searchOpt.orderType,
              addrType: this.searchOpt.addrType,
              searchWord: this.searchOpt.searchTxt,
              recordsPerPage: this.listStatus.dataOnPage,
              pageNo: this.listStatus.currentPage,
              addr: this.searchOpt.addr,
              auth: true,
            };
          }

          // 유효성 검사
          // 1. 타입
          if (
            searchOption.orderType === null ||
            searchOption.orderType === undefined
          )
            searchOption.orderType = "";

          var orderTypeBoo = /^[0-9]{1,100}$/;

          if (!orderTypeBoo.test(searchOption.orderType)) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = "리스트를 불러오는데 실패하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 2. 주소기준
          if (
            searchOption.addrType === null ||
            searchOption.addrType === undefined
          )
            searchOption.addrType = "";

          var addrTypeBoo = /^[0-9]{1,100}$/;

          if (!addrTypeBoo.test(searchOption.addrType)) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = "리스트를 불러오는데 실패하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 3. 검색어
          if (
            searchOption.searchWord === null ||
            searchOption.searchWord === undefined
          )
            searchOption.searchWord = "";

          if (
            searchOption.searchWord.length < 0 ||
            searchOption.searchWord.length > 200
          ) {
            this.SET_POPUP(true);
            this.popupSet.title = "검색어 확인";
            this.popupSet.content = "200자 이내의 검색어를 입력해주세요.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 4. 페이지당 보여줄 수
          if (
            searchOption.recordsPerPage === null ||
            searchOption.recordsPerPage === undefined
          )
            searchOption.recordsPerPage = "";

          var recordsPerPageBoo = /^[0-9]{1,100}$/;

          if (!recordsPerPageBoo.test(searchOption.recordsPerPage)) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = "리스트를 불러오는데 실패하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }
          // console.log(searchOption)
          await this.BRING_SEARCH_LIST(searchOption).then(() => {
            //console.log("가져온 결과값", this.GET_SEARCH_LIST);
            this.listStatus.totalDataCnt =
              this.GET_SEARCH_LIST.param.totalCount;
            /** this.listStatus.currentPage = this.GET_SEARCH_LIST.param.pageNo;*/
            /** this.listStatus.dataOnPage = this.GET_SEARCH_LIST.param.recordsPerPage; */
            this.searchOpt.preSearchTxt = this.GET_SEARCH_LIST.param.searchWord;
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.hospital = this.GET_SEARCH_LIST.list;
              this.preParePagination();
            }, 100);
          });
        } catch (error) {
          //console.log("error: ", error);
          setTimeout(() => {
            this.SET_CHILD_LOADER(false);
          }, 800);
        }
      } else {
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content = "검색어에 공백을 입력하실 수 없습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      }

      const focusControl = document.getElementById("focusControl");
      //console.log(focusControl);
      focusControl.focus({ preventScroll: true });
    },
    selectDoctor(drId, index) {
      const doctorInfo = {
        prevUrl: "/v/request/researchHospital",
        proObj: this.hospital[index].proObj,
      };
      //console.log("PR페이지로 전송할 내역", doctorInfo);
      this.SET_LIST_DOC_INFO(doctorInfo);
      //this.$router.push(`/v/pr/view/${drId}`, '_blank');
      /*let route = this.$router.resolve({ path: `/dr/${drId}` });
      window.open(route.href, "_blank");*/
      window.open(
        "https://www.drrefer.net/dr/" + drId,
        "팝업",
        "width=1000px, height=1000px, scrollbars=yes, resizable=no"
      );
    },
    checkPick(type, order) {
      /*console.log("cheryPick 동작");
      console.log(order);*/
      /** checkPick 참조 */
      if (type === "first") {
        for (let i = 0; i < this.getCheckValue.default.length; i++) {
          if (
            this.searchOpt.searchTxt !== null &&
            this.searchOpt.searchTxt !== ""
          ) {
            if (order !== i) {
              this.getCheckValue.default[i].flag = false;
            } else {
              this.getCheckValue.default[i].flag = true;
              this.searchOpt.orderType = order + 1;
            }
            this.searchDoctor();
          }
        }
      } else if (type === "second") {
        for (let j = 0; j < this.getCheckValue.distance.length; j++) {
          this.searchOpt.addr = null;
          if (order !== j) {
            this.getCheckValue.distance[j].flag = false;
          } else {
            this.getCheckValue.distance[0].flag = true;
            this.searchOpt.addrType = 1;
            if (order === 1) {
              this.searchAddress();
            }
          }
        }
      }
    },
  },
  destroyed() {
    this.setCount = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/ResearchHospital";
</style>
