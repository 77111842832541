import { render, staticRenderFns } from "./ResearchHospital.vue?vue&type=template&id=66539523&scoped=true&"
import script from "./ResearchHospital.vue?vue&type=script&lang=js&"
export * from "./ResearchHospital.vue?vue&type=script&lang=js&"
import style0 from "./ResearchHospital.vue?vue&type=style&index=0&id=66539523&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66539523",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
